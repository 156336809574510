import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import { useNavigate } from 'react-router-dom';
import { LinearGradient } from 'react-text-gradients'


const blogs = [
  {
    id: 1,
    title: "Why choose sociology as your optional for UPSC?",
    image: "", 
    description: "A note on why do you want to opt sociology as your UPSC optional.",
    content: `Most aspirants begin their UPSC journey in their early or mid twenties. It is a phase in life where you start
contemplating many aspects of life. Why is it important to have a job? Is it right for my family to put restrictions
in my life? How is my generation seeing love and family? We seek answers for these questions and many more.
Sociology becomes a great optional subject for this very reason. It enables us to look at these fundamental
questions about our own social life with depth and passion. As Sociology deals with everyday life, it is equally
relatable to candidates from social science and non social science backgrounds. When we start to engage with
something that's very relatable to us, learning flows effortlessly. After a point, sociological thinking becomes an
integral part of our life.
And of course, taking up Sociology as your optional has solid benefits from the examination point of view as well.
The relatively small syllabus, easy availability of quality material, a strong peer group, and high scores hitting
300+ make sociology a lucrative optional to clear the Civil Service Examination.
Even beyond the Optional paper, Sociology shall complement your learning and performance in other Mains
papers and Interview. The Indian society section of GS 1, Social Justice section of GS 2, and Society-oriented
questions in GS 4 and Essay paper become much easier with a background in Sociology. Finally, a UPSC CSE
interview hardly gets done without a question on the society you are a part of!
UPSC is a journey of reflections and introspections as much as it is an examination. Choosing sociology shall set
you on a path of success in the Civil Service examination while also empowering you as an individual.
Summing up, Sociology is an optional that offers the dream combination of passion and efficiency that is needed
to clear the toughest examination in the country. What more do you want, Champ?`
  },
  
];

function Blog() {
  const navigate = useNavigate();
  
    const handleReadMore = (blog) => {
      navigate('/blog-detail', { state: { title: blog.title, content: blog.content } });
    };
  return (
    <div>
        <Nav/>
      <div className='flex flex-col items-center justify-center py-10 bg-gray-100 pt-20'>
      <h1 className='text-2xl  mb-2 pt-28'>
        <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
            Blog
        </LinearGradient>
        </h1>
      <p className='text-lg text-gray-600 text-center mb-8 sm:px-4 lg:px-36'>
        Welcome to our blog! Here, we explore various topics ranging from programming tutorials to insights on sociology. 
        Dive in to expand your knowledge and stay updated with the latest trends.
      </p>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 pt-20'>
        {blogs.map(blog => (
          <div key={blog.id} className='bg-transparent rounded-lg  transition-transform transform hover:scale-105 max-w-xs mx-auto'> {/* Added max-w-xs and mx-auto */}
            <img src={blog.image} alt={blog.title} className='w-full h-40 object-cover' /> {/* Reduced height */}
            <div className='p-4'>
              <h2 className='text-lg font-bold mb-2'>
                <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
                  {blog.title}
                </LinearGradient>
              </h2> 
              <p className='text-gray-600 mb-4'>{blog.description}</p>
              <button 
                onClick={() => handleReadMore(blog)} 
                className='border-2 bg-white border-blue-500 text-blue-500 py-2 px-4 rounded-lg transition duration-300 hover:bg-gradient-to-r from-[#2A52BE] to-[#8A2BE2] hover:text-white'
              >
                Read More
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
        <Footer/>
    </div>
  )
}

export default Blog