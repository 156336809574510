import React, { useRef } from 'react'
import Bhasi from "../Pics/Bhasi 1.png"
import { LinearGradient } from 'react-text-gradients'
import { abtmntr } from '../Constants/Constants';
import ganga from '../Pics/Ganga.jpeg'
import sunish from '../Pics/Suneesh.jpeg'
import { motion, useInView } from "framer-motion";

const facultyData = [
  {
    id: 1,
    name: "Bhasil Muhammed",
    role: "Faculty of Sociology",
    image: Bhasi,
    description: "Lead Sociologist with 15 years of research experience in urban sociology and social dynamics."
  },
  {
    id: 2,
    name: "Ganga Krishna Kumar",
    role: "Content Head",
    image: ganga,
    description: "Lead Sociologist with 15 years of research experience in urban sociology and social dynamics."
  },
  {
    id: 3,
    name: "Sunish S Kumar",
    role: "chief Mentor",
    image: sunish,
    description: "Lead Sociologist with 15 years of research experience in urban sociology and social dynamics."
  },
];

// Animation variants
const container = (delay) => ({
  hidden: { y: 100, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, delay: delay },
  },
});

function Mentors() {
  // Create ref for detecting when section is in view
  const mentorsRef = useRef(null);
  const isInView = useInView(mentorsRef, { once: true, threshold: 0.2 });

  return (
    <div 
      ref={mentorsRef}
      className='flex flex-col py-8 items-center md:mx-8 lg:px-24 mt-20'
    >
      {/* Know our Mentors*/}
      <motion.h6 
        variants={container(0.2)}
        initial='hidden'
        animate={isInView ? 'visible' : 'hidden'}
        className='text-xl font-semibold text-center'
      >
        <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
          Get to know our mentors!
        </LinearGradient>
      </motion.h6>
      
      <motion.p 
        variants={container(0.3)}
        initial='hidden'
        animate={isInView ? 'visible' : 'hidden'}
        className='text-md text-gray-600 mt-8 text-center lg:mx-32'
      >
        {abtmntr}
      </motion.p>
      
      <div className='flex flex-col lg:flex-row items-center align-middle mt-8 gap-6'>
        {facultyData.map((faculty, index) => (
          <motion.div 
            key={faculty.id}
            variants={container(0.4 + index * 0.1)} // Staggered delay
            initial='hidden'
            animate={isInView ? 'visible' : 'hidden'}
            className='bg-gradient-to-r from-[#2a51bed6] to-[#892be2b4] rounded-lg shadow-2xl transition-transform transform hover:scale-105 overflow-visible max-w-xs mx-auto my-auto'
          >
            <img 
              src={faculty.image} 
              alt={faculty.name} 
              className='w-full h-72 object-cover rounded-2xl'
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `/api/placeholder/300/400`;
              }}
            />
            <div className='m-4'>
              <h2 className='text-lg font-bold mb-2 text-white text-center'>{faculty.name}</h2>
              <h4 className='text-md m-2 text-gray-200 text-center'>{faculty.role}</h4>
              <p className='text-sm font-light mb-4 text-gray-300'>{faculty.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  )
}

export default Mentors