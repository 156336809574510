import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Courses from './Pages/Courses';
import About from './Pages/About';
import Blog from './Pages/Blog'; // Assuming this is your Blg component
import BlogDetail from './Pages/BlogDetail'; // Import your new BlogDetail component
import './App.css';
import Contact from './Pages/Contact';
import Syllabus from './Pages/Syllabus';

function App() {
  return (
    <div className="flex flex-col bg-gray-100 overflow-x-hidden">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-detail" element={<BlogDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/materials" element={<Syllabus/>} />
        </Routes>
      </Router>
    </div>
    
  );
}



export default App;