import React from 'react';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import { syllabusContent } from '../Constants/Constants';

function Syllabus() {
  const syllabusFiles = [
    {
      id: 1,
      title: 'Sociology Syllabus - Paper 1',
      description: 'Detailed syllabus for Sociology Paper 1.',
      fileUrl: 'https://drive.google.com/file/d/1f6B0BTcxdR_3w4Ag34MyjOz4waxeKFuN/view?usp=sharing',  
    },
    
  ];

  return (
    <div className='flex flex-col min-h-screen'>
      <Nav />
      <div className='flex-grow flex flex-col items-center pt-72 pb-10'> {/* Adjusted pt for better spacing */}
        <h3 className='text-2xl text-transparent bg-clip-text bg-gradient-to-l from-blue-600 to-purple-600 mb-8'>
          Study Materials
        </h3>

        <p>{syllabusContent}</p>



        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full px-4 md:px-8 lg:px-16"> {/* Responsive grid */}
          {syllabusFiles.map((file) => (
            <div key={file.id} className="bg-transparent rounded-lg  overflow-hidden"> {/* Card Styling */}
              <div className="p-6">
                <h4 className="text-xl mb-2 bg-clip-text bg-gradient-to-l from-blue-600 to-purple-600">{file.title}</h4>
                <p className="text-gray-600 mb-4">{file.description}</p>
                <a
                  href={file.fileUrl}
                  download={`${file.title.replace(/ /g, '_')}.pdf`} // Clean filename
                  className="border-2 bg-white border-blue-500 text-blue-500 py-2 px-4 rounded-lg transition duration-300 hover:bg-gradient-to-r from-[#2A52BE] to-[#8A2BE2] hover:text-white"
                >
                  Download
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Syllabus;