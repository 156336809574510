
import { FaChalkboardTeacher } from "react-icons/fa";
import checklist from '../Pics/checklist-1-7.png'
import team from '../Pics/team-presentation-4-33.png'




export const tagline = `The best Sociology Optional IAS academy`;

export const smtag = `"Deep Roots in Sociology, High Scores in UPSC – Trivandrum's Trusted Academy"`;

export const hmcourse1 =`Learn from the Best Mentors`;

export const hmcourse2 = `Our efficient team of mentors are committed to providing the best and exceptional courses to get you ready to  step into the dream of a civil service job.`;

export const course_tag = (<>
    Our mentorship programs are designed specifically for civil service aspirants At Civils360,<br/> we offer a range of courses from foundation building to advanced civil service programs for each student.
    </>
) 

export const welcome = `Welcome to The Sociologist, the most trusted destination for Sociology Optional coaching for UPSC. With a high success rate and expert mentorship, we provide a structured approach to help aspirants master sociology and excel in the Civil Services Examination.

`

export const whysoc = (<>Most aspirants begin their UPSC journey in their early or mid twenties. It is a phase in life where you start
contemplating many aspects of life. Why is it important to have a job? Is it right for my family to put restrictions
in my life? How is my generation seeing love and family? We seek answers for these questions and many more.
Sociology becomes a great optional subject for this very reason. It enables us to look at these fundamental
questions about our own social life with depth and passion. As Sociology deals with everyday life, it is equally
relatable to candidates from social science and non social science backgrounds. When we start to engage with
something that's very relatable to us, learning flows effortlessly. </>)

export const abtmntr = `Our mentors are seasoned educators and subject-matter experts dedicated to guiding you through your IAS journey. With years of experience in teaching and mentoring, they bring deep insights, structured strategies, and personalized support to help you excel. Whether it's mastering concepts, refining answer-writing skills, or staying updated with current affairs, our mentors are here to ensure your success.`

export const COURSES = [
    {
        id:1,
        name: "Sociology Optional Classes",
        image:team,
        description: (
            <>
                <ul className="list-disc pl-5">
                    <li>Covering both Paper 1 and 2</li>
                    <li>Comprehensive study material</li>
                    <li>Sectional tests</li>
                    <li>Personalized feedback</li>
                    <li>Answer Writing programme</li>
                    <li>Personal mentorship</li>
                </ul>
            </>
        ),
    },
    {
        id:2,
        name: "Sociology Optional test series",
        image:checklist,
        description: (
            <>
                <ul className="list-disc pl-5">
                    <li>Sectional and Full length tests</li>
                    <li>Personalised Feedback</li>
                    <li>⁠Model Answer Key</li>
                    <li>personal Mentorship</li>
                    <li>value Addition classes</li>
                </ul>
            </>
        ),
    },
];


export const syllabusContent = (
    <>
      <h3>Syllabus of Sociology Paper - I</h3>
      <h4>FUNDAMENTALS OF SOCIOLOGY</h4>
      <ol>
        <li>
          Sociology - The Discipline:
          <ul>
            <li>(a) Modernity and social changes in Europe and emergence of Sociology.</li>
            <li>(b) Scope of the subject and comparison with other social sciences.</li>
            <li>(c) Sociology and common sense.</li>
          </ul>
        </li>
        <li>
          Sociology as Science:
          <ul>
            <li>(a) Science, scientific method, and critique.</li>
            <li>(b) Major theoretical strands of research methodology.</li>
            <li>(c) Positivism and its critique.</li>
            <li>(d) Fact value and objectivity.</li>
            <li>(e) Non-positivist methodologies.</li>
          </ul>
        </li>
        <li>
          Research Methods and Analysis:
          <ul>
            <li>(a) Qualitative and quantitative methods.</li>
            <li>(b) Techniques of data collection.</li>
            <li>(c) Variables, sampling, hypothesis, reliability, and validity.</li>
          </ul>
        </li>
        <li>
          Sociological Thinkers:
          <ul>
            <li>(a) Karl Marx - Historical materialism, mode of production, alienation, class struggle.</li>
            <li>(b) Emile Durkheim - Division of labour, social fact, suicide, religion and society.</li>
            <li>(c) Max Weber - Social action, ideal types, authority, bureaucracy, protestant ethic and the spirit of capitalism.</li>
            <li>(d) Talcolt Parsons - Social system, pattern variables.</li>
            <li>(e) Robert K. Merton - Latent and manifest functions, conformity and deviance, reference groups.</li>
            <li>(f) Mead - Self and identity.</li>
          </ul>
        </li>
        <li>
          Stratification and Mobility:
          <ul>
            <li>(a) Concepts - equality, inequality, hierarchy, exclusion, poverty, and deprivation.</li>
            <li>(b) Theories of social stratification - Structural functionalist theory, Marxist theory, Weberian theory.</li>
            <li>(c) Dimensions - Social stratification of class, status groups, gender, ethnicity and race.</li>
            <li>(d) Social mobility - open and closed systems, types of mobility, sources and causes of mobility.</li>
          </ul>
        </li>
        <li>
          Works and Economic Life:
          <ul>
            <li>(a) Social organization of work in different types of society - slave society, feudal society, industrial capitalist society.</li>
            <li>(b) Formal and informal organization of work.</li>
            <li>(c) Labour and society.</li>
          </ul>
        </li>
        <li>
          Politics and Society:
          <ul>
            <li>(a) Sociological theories of power.</li>
            <li>(b) Power elite, bureaucracy, pressure groups and political parties.</li>
            <li>(c) Nation, state, citizenship, democracy, civil society, ideology.</li>
            <li>(d) Protest, agitation, social movements, collective action, revolution.</li>
          </ul>
        </li>
        <li>
          Religion and Society:
          <ul>
            <li>(a) Sociological theories of religion.</li>
            <li>(b) Types of religious practices: animism, monism, pluralism, sects, cults.</li>
            <li>(c) Religion in modern society: religion and science, secularization, religious revivalism, fundamentalism.</li>
          </ul>
        </li>
        <li>
          Systems of Kinship:
          <ul>
            <li>(a) Family, household, marriage.</li>
            <li>(b) Types and forms of family.</li>
            <li>(c) Lineage and descent.</li>
            <li>(d) Patriarchy and sexual division of labour.</li>
            <li>(e) Contemporary trends.</li>
          </ul>
        </li>
        <li>
          Social Change in Modern Society:
          <ul>
            <li>(a) Sociological theories of social change.</li>
            <li>(b) Development and dependency.</li>
            <li>(c) Agents of social change.</li>
            <li>(d) Education and social change.</li>
            <li>(e) Science, technology, and social change.</li>
          </ul>
        </li>
      </ol>
      <h3>Syllabus of Sociology Paper - II</h3>
      <h4>INDIAN SOCIETY: STRUCTURE AND CHANGE</h4>
      <h4>A. Introducing Indian Society:</h4>
      <ol>
        <li>
          Perspectives on the Study of Indian Society:
          <ul>
            <li>(a) Indology (G.S. Ghure).</li>
            <li>(b) Structural functionalism (M. N. Srinivas).</li>
            <li>(c) Marxist sociology (A. R. Desai).</li>
          </ul>
        </li>
        <li>
          Impact of colonial rule on Indian society:
          <ul>
            <li>(a) Social background of Indian nationalism.</li>
            <li>(b) Modernization of Indian tradition.</li>
            <li>(c) Protests and movements during the colonial period.</li>
            <li>(d) Social reforms.</li>
          </ul>
        </li>
      </ol>
      <h4>B. Social Structure:</h4>
      <ol>
        <li>
          Rural and Agrarian Social Structure:
          <ul>
            <li>(a) The idea of Indian village and village studies</li>
            <li>(b) Agrarian social structure— evolution of land tenure system, land reforms.</li>
          </ul>
        </li>
        <li>
          Caste System:
          <ul>
            <li>(a) Perspectives on the study of caste systems: G. S. Ghurye, M. N. Srinivas, Louis Dumont, Andre Beteille.</li>
            <li>(b) Features of caste system.</li>
            <li>(c) Untouchability-forms and perspectives</li>
          </ul>
        </li>
        <li>
          Tribal Communities in India:
          <ul>
            <li>(a) Definitional problems.</li>
            <li>(b) Geographical spread.</li>
            <li>(c) Colonial policies and tribes.</li>
            <li>(d) Issues of integration and autonomy.</li>
          </ul>
        </li>
        <li>
          Social Classes in India:
          <ul>
            <li>(a) Agrarian class structure.</li>
            <li>(b) Industrial class structure.</li>
            <li>(c) Middle classes in India.</li>
          </ul>
        </li>
        <li>
          Systems of Kinship in India:
          <ul>
            <li>(a) Lineage and descent in India.</li>
            <li>(b) Types of kinship systems.</li>
            <li>(c) Family and marriage in India.</li>
            <li>(d) Household dimensions of the family.</li>
            <li>(e) Patriarchy, entitlements, and sexual division of labour.</li>
          </ul>
        </li>
        <li>
          Religion and Society:
          <ul>
            <li>(a) Religious communities in India.</li>
            <li>(b) Problems of religious minorities.</li>
          </ul>
        </li>
      </ol>
      <h4>C. Social Changes in India:</h4>
      <ol>
        <li>
          Visions of Social Change in India:
          <ul>
            <li>(a) Idea of development planning and mixed economy.</li>
            <li>(b) Constitution, law, and social change.</li>
            <li>(c) Education and social change.</li>
          </ul>
        </li>
        <li>
          Rural and Agrarian Transformation in India:
          <ul>
            <li>(a) Programmes of rural development, Community Development Programme, cooperatives, poverty alleviation schemes.</li>
            <li>(b) Green revolution and social change.</li>
            <li>(c) Changing modes of production in Indian agriculture.</li>
            <li>(d) Problems of rural labour, bondage, migration.</li>
          </ul>
        </li>
        <li>
          Industrialization and Urbanisation in India:
          <ul>
            <li>(a) Evolution of modern industry in India.</li>
            <li>(b) Growth of urban settlements in India.</li>
            <li>(c) Working class: structure, growth, class mobilization.</li>
            <li>(d) Informal sector, child labour.</li>
            <li>(e) Slums and deprivation in urban areas.</li>
          </ul>
        </li>
        <li>
          Politics and Society:
          <ul>
            <li>(a) Nation, democracy and citizenship.</li>
            <li>(b) Political parties, pressure groups, social and political elite.</li>
            <li>(c) Regionalism and decentralization of power.</li>
            <li>(d) Secularization.</li>
          </ul>
        </li>
        <li>
          Social Movements in Modern India:
          <ul>
            <li>(a) Peasants and farmers' movements.</li>
            <li>(b) Women’s movement.</li>
            <li>(c) Backward classes & Dalit movements.</li>
            <li>(d) Environmental movements.</li>
            <li>(e) Ethnicity and Identity movements.</li>
          </ul>
        </li>
        <li>
          Population Dynamics:
          <ul>
            <li>Population size, growth, composition and distribution.</li>
            <li>Components of population growth: birth, death, migration.</li>
            <li>Population Policy and family planning.</li>
            <li>Emerging issues: ageing, sex ratios, child and infant mortality, reproductive health.</li>
          </ul>
        </li>
        <li>
          Challenges of Social Transformation:
          <ul>
            <li>(a) Crisis of development: displacement, environmental problems and sustainability.</li>
            <li>(b) Poverty, deprivation and inequalities.</li>
            <li>(c) Violence against women.</li>
            <li>(d) Caste conflicts.</li>
            <li>(e) Ethnic conflicts, communalism, religious revivalism.</li>
            <li>(f) Illiteracy and disparities in education.</li>
          </ul>
        </li>
      </ol>
    </>
  );