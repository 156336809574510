import React, { useEffect, useRef } from 'react';
import Nav from '../Components/Nav';
import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';
import { tagline, smtag, whysoc } from '../Constants/Constants';
import { LinearGradient } from 'react-text-gradients';
import { FaBookOpen, FaUserFriends, FaRegLightbulb, FaClipboardList, FaNewspaper, FaUsers, FaUserTie } from 'react-icons/fa';
import { IoManSharp } from "react-icons/io5";
import Course from '../Components/Course';
import Mentors from '../Components/Mentors';
import ScrollVelocity from '../Components/ScrollVelocity';
import img1 from '../Pics/img1.png';
import { motion, useInView } from "framer-motion";
import { welcome } from '../Constants/Constants';


const whySociologist = [
  {
    id: 1,
    main: "Experienced Faculty",
    icon: <IoManSharp className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Our experienced trainers are the backbone of our institute. They are completely aware of the ins and outs of civil service. They stand beside civil service aspirants until they create their success stories."
  },
  {
    id: 2,
    main: "Comprehensive Study Material",
    icon: <FaBookOpen className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Get access to well-structured and updated study material designed by experts to cover all aspects of the syllabus."
  },
  {
    id: 3,
    main: "Personalized Mentorship",
    icon: <FaUserFriends className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Receive one-on-one guidance and support from experienced mentors who will help you navigate your preparation journey."
  },
  {
    id: 4,
    main: "In-depth Curriculum",
    icon: <FaRegLightbulb className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Our meticulously designed curriculum covers every aspect of the civil service syllabus in detail. It ensures a deep understanding of core concepts, integrates real-world examples, and follows a structured approach to maximize learning efficiency."
  },
  {
    id: 5,
    main: "Regular Assessments & Feedback",
    icon: <FaClipboardList className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Stay on track with periodic tests and detailed performance analysis to identify strengths and areas for improvement."
  },
  {
    id: 6,
    main: "Current Affairs & Updates",
    icon: <FaNewspaper className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Stay updated with daily current affairs analysis and expert insights on relevant topics."
  },
  {
    id: 7,
    main: "Interactive Learning Environment",
    icon: <FaUsers className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Engage in dynamic discussions, group activities, and interactive sessions to enhance your learning experience."
  },
  {
    id: 8,
    main: "Mock Interviews & Personality Development",
    icon: <FaUserTie className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Prepare for interviews with simulated sessions and expert feedback to boost confidence and communication skills."
  },
];

const container = (delay) => ({
  hidden: { y: 100, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, delay: delay },
  },
});

function Home() {
  const navigate = useNavigate();
  
  // Create separate refs for different sections
  const whySociologistRef = useRef(null);
  const whyChooseRef = useRef(null);
  
  // Create separate inView states for different sections
  const isWhySociologistInView = useInView(whySociologistRef, { once: true, threshold: 0.2 });
  const isWhyChooseInView = useInView(whyChooseRef, { once: true, threshold: 0.2 });

  return (
    <div className='flex flex-col items-center relative'>
      <Nav />
      <div className='flex flex-col w-full'>
        {/* Hero*/}
        <div className="min-h-screen w-full bg-white [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#63e_100%)] flex items-center justify-center shadow-xl shadow-purple-300">
          {/* Added flex classes */}
          <div className='flex flex-col items-center justify-center md:px-14'>
            <div className='text-center'>
              <motion.h1
                variants={container(0.2)}
                initial='hidden'
                animate='visible'
                className='text-3xl md:text-8xl font-bold text-transparent bg-clip-text bg-gradient-to-l from-blue-600 to-purple-600 mt-2'>{tagline}</motion.h1>
              <motion.h6
                variants={container(0.3)}
                initial='hidden'
                animate='visible'
                className='mt-4 text-8px md:text-xl text-gray-700'>
                <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
                  {smtag}
                </LinearGradient>
              </motion.h6>
            </div>
          </div>
        </div>

        
        <div className='flex flex-col items-center h-auto'>
          <div className='flex flex-col items-center w-10/12 py-28 text-xl'>
            <motion.div
              variants={container(0.3)}
              initial='hidden'
              animate='visible'
              className='flex flex-col items-center'
            >
              <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
                About the Sociologist
              </LinearGradient>

              <p className='mt-4 text-sm text-center text-gray-600'>{welcome}</p>
            </motion.div>
          </div>
        </div>


        <div className='hidden sm:block lg:py-20 px-4 md:px-28'>
          <h3 className='text-8xl'>
            <ScrollVelocity
              texts={['Admission Open!', 'Register Now!']}
              className="custom-scroll-text text-[#3756df]"
            />
          </h3>
        </div>

        <Course />

      {/* Explore Syllabus Section */}
        <motion.div 
          className="flex flex-col items-center justify-center py-20  text-gray-700"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-3xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-l from-blue-600 to-purple-600">Explore Syllabus</h2>
          <p className="text-lg text-center mb-8">
            Dive into our comprehensive study materials and curriculum designed to help you excel in your preparation.
          </p>
          <motion.button
            onClick={() => navigate('/materials')}
            whileHover={{
              scale: 1.1,
              backgroundColor: "#f7f7f7",
              transition: { duration: 0.3 }
            }}
            className="px-6 py-3 bg-transparent border-blue-600 text-blue-600  rounded-lg"
          >
            Go to Materials
          </motion.button>
        </motion.div>


        {/* Why Sociologist*/}
        <div ref={whySociologistRef} className='flex flex-col py-8 px-4 md:px-8 lg:px-32'>
          <motion.h6
            variants={container(0.4)}
            initial='hidden'
            animate={isWhySociologistInView ? 'visible' : 'hidden'}
            className='flex flex-col items-center text-2xl'>
            <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
              Why Sociologist?
            </LinearGradient>
          </motion.h6>
        </div>

        <div className='flex flex-col lg:flex-row lg:flex-wrap lg:justify-evenly gap-8 px-4 md:px-8 lg:px-32'>
          {whySociologist.map((point, index) => (
            <motion.div
              key={point.id}
              variants={container(0.5 + index * 0.1)}
              initial='hidden'
              animate={isWhySociologistInView ? 'visible' : 'hidden'}
              className='p-4 border my-4 w-full lg:w-1/3 bg-gradient-to-r from-[#2A52BE] to-[#8A2BE2] text-white rounded-lg transition-transform hover:scale-105'
            >
              <div className='flex items-start'>
                {point.icon}
                <div>
                  <h2 className='text-md font-semibold mb-1'>{point.main}</h2>
                  <p className='text-sm text-gray-200'>{point.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <div className='flex flex-col lg:flex-row w-full h-fit py-8 px-4 md:px-8 lg:px-24 mt-20 mb-8' ref={whyChooseRef}>
          {/* Why to choose Sociology?*/}
          <div className='flex flex-col w-full lg:w-1/2 px-4 justify-center'>
            <motion.h6 
              variants={container(0.4)}
              initial='hidden'
              animate={isWhyChooseInView ? 'visible' : 'hidden'}
              className='text-xl'
            >
              <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
                -Why choose SOCIOLOGY as your Optional in UPSC?
              </LinearGradient>
            </motion.h6>
            <motion.p 
              variants={container(0.5)}
              initial='hidden'
              animate={isWhyChooseInView ? 'visible' : 'hidden'}
              className='mt-6 sm:text-xs lg:text-xl text-gray-600'
            >
              {whysoc}
            </motion.p>
          </div>
          <motion.div 
            variants={container(0.6)}
            initial='hidden'
            animate={isWhyChooseInView ? 'visible' : 'hidden'}
            className='flex flex-row lg:flex-col w-full lg:w-1/2 px-4'
          >
            <img src={img1} alt="Sociology for UPSC" className='w-fit h-85 object-cover' />
          </motion.div>
        </div>

        <Mentors />

        <div className='flex flex-col py-8 px-4 items-center md:px-8 lg:px-24 mt-20'>
          {/*Youtube video link */}
          <motion.h6
            variants={container(0.5)}
            initial='hidden'
            animate={isWhyChooseInView ? 'visible' : 'hidden'}
            className='text-xl font-semibold'>
            <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
              Watch our Videos!
            </LinearGradient>
          </motion.h6>

          <motion.div
            variants={container(0.6)}
            initial='hidden'
            animate={isWhyChooseInView ? 'visible' : 'hidden'}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6 shadow-md">
            {/* Replace the src with actual video URLs */}
            {[
              { id: "VIDEO_ID_1", title: "Introduction to Sociology" },
              { id: "VIDEO_ID_2", title: "UPSC Preparation Tips" },
              { id: "VIDEO_ID_3", title: "Mock Interview Session" }
            ].map((video, index) => (
              <iframe
                key={index}
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${video.id}`}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg shadow-md"
              ></iframe>
            ))}
          </motion.div>
          <motion.button
            className='mt-8 border rounded-2xl px-8 py-4 text-white bg-gradient-to-r from-[#2a51bed6] to-[#892be2b4] transition-transform transform hover:scale-105'
            onClick={() => window.open('https://youtube.com/@thesociologistupsc?si=XoLbliTRBF1N8kky', '_blank')}
          >
            Watch More
          </motion.button>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home