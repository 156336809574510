import React, { useState, useRef } from 'react';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import { MdOutlinePhone } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import Swal from 'sweetalert2';
import { motion, useInView } from "framer-motion";

// Animation variants
const container = {
  hidden: { y: 50, opacity: 0 },
  visible: (delay = 0) => ({
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 1,
      delay: delay,
    },
  }),
};

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  // Refs for animation triggers
  const headerRef = useRef(null);
  const cardsRef = useRef(null);
  const formRef = useRef(null);

  const isHeaderInView = useInView(headerRef, { once: true, threshold: 0.1 });
  const isCardsInView = useInView(cardsRef, { once: true, threshold: 0.1 });
  const isFormInView = useInView(formRef, { once: true, threshold: 0.1 });

  const handlePhoneClick = () => {
    window.location.href = "tel:+919995160836";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:thesociologistupsc@gmail.com";
  };

  const handleLocationClick = () => {
    window.open("https://maps.app.goo.gl/wtqFKbkpvqrBZN1H8", "_blank");
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("access_key", "866a34a9-a322-4ef1-ae93-6124a9a5d47e");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });

      const data = await response.json();

      if (data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Message Sent!',
          text: 'Your message has been submitted successfully.',
        });
        event.target.reset();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Submission Failed',
          text: data.message || 'An error occurred while submitting the form.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Submission Failed',
        text: 'A network error occurred. Please try again later.',
      });
    }
  };

  return (
    <div className='w-full h-fit bg-gray-100'>
      <Nav />
      <div className='flex flex-col items-center w-full justify-center pt-44 pb-20'>
        <motion.h2 
          ref={headerRef}
          variants={container}
          initial="hidden"
          animate={isHeaderInView ? "visible" : "hidden"}
          custom={0.2}
          className='text-2xl text-transparent bg-clip-text bg-gradient-to-l from-blue-600 to-purple-600'
        >
          Contact
        </motion.h2>

        <div 
          ref={cardsRef}
          className='flex flex-col lg:flex-row gap-10 items-center justify-center px-10 py-10'
        >
          {/* Phone */}
          <motion.div
            variants={container}
            initial="hidden"
            animate={isCardsInView ? "visible" : "hidden"}
            custom={0.3}
            className='w-80 h-56 flex flex-col border bg-white items-center justify-center text-gray-600 rounded-lg cursor-pointer transition-transform hover:scale-105'
            onClick={handlePhoneClick}
          >
            <MdOutlinePhone className='text-6xl mt-4' />
            <p className='text-sm py-2 px-4 text-center mt-4'>+91-9995160836</p>
          </motion.div>

          {/* Email */}
          <motion.div
            variants={container}
            initial="hidden"
            animate={isCardsInView ? "visible" : "hidden"}
            custom={0.4}
            className='w-80 h-56 flex flex-col border bg-white items-center justify-center text-gray-600 rounded-lg cursor-pointer transition-transform hover:scale-105'
            onClick={handleEmailClick}
          >
            <CiMail className='text-6xl mt-4' />
            <p className='text-sm py-2 px-4 text-center mt-4'>thesociologistupsc@gmail.com</p>
          </motion.div>

          {/* Location */}
          <motion.div
            variants={container}
            initial="hidden"
            animate={isCardsInView ? "visible" : "hidden"}
            custom={0.5}
            className='w-80 h-56 flex flex-col border bg-white items-center justify-center text-gray-600 rounded-lg cursor-pointer transition-transform hover:scale-105'
            onClick={handleLocationClick}
          >
            <CiLocationOn className='text-6xl mt-4' />
            <p className='text-sm py-2 px-4 text-center mt-4'>The Sociologist</p>
          </motion.div>
        </div>

        {/* Contact Form */}
        <motion.div 
          ref={formRef}
          variants={container}
          initial="hidden"
          animate={isFormInView ? "visible" : "hidden"}
          custom={0.6}
          className='flex flex-col bg-white p-8 lg:px-52 lg:py-28 rounded-lg border w-full sm:max-w-lg lg:max-w-4xl'
        >
          <h6 className='flex flex-col text-xl text-transparent bg-clip-text bg-gradient-to-l from-blue-600 to-purple-600 py-4 items-center'>
            Send us a Message
          </h6>
          
          <form onSubmit={onSubmit} className='flex flex-col'>
            <label htmlFor="name" className="text-sm font-semibold mb-1">Full Name</label>
            <input 
              type="text" 
              name='name'
              placeholder="Enter your name" 
              className="border border-gray-300 rounded-lg p-2 mb-4"
              required
            />
            
            <label htmlFor="email" className="text-sm font-semibold mb-1">Email</label>
            <input 
              type="email" 
              name='email' 
              placeholder="Enter your email" 
              className="border border-gray-300 rounded-lg p-2 mb-4"
              required
            />

            <label htmlFor="phone" className="text-sm font-semibold mb-1">Phone Number</label>
            <input 
              type="tel" 
              name='phone' 
              placeholder="Enter your phone number" 
              className="border border-gray-300 rounded-lg p-2 mb-4"
              required
            />

            <label htmlFor="message" className="text-sm font-semibold mb-1">Message</label>
            <textarea 
              id="message" 
              placeholder="Enter your message"
              name='message'
              className="border border-gray-300 rounded-lg p-2 mb-4"
              rows="4"
              required
            ></textarea>

            <button 
              type="submit" 
              className='bg-blue-600 text-white rounded-lg py-2 px-4 transition duration-300 hover:bg-blue-700'
            >
              Send Message
            </button>
          </form>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;