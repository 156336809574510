import React, { useRef } from 'react';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import { LinearGradient } from 'react-text-gradients';
import Mentors from '../Components/Mentors';
import { motion, useInView } from "framer-motion";

// Animation variants
const container = {
  hidden: { y: 50, opacity: 0 },
  visible: (delay = 0) => ({
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 1,
      delay: delay,
    },
  }),
};

function About() {
  const headingRef = useRef(null);
  const paragraphRef = useRef(null);
  const mentorsRef = useRef(null);

  const isHeadingInView = useInView(headingRef, { once: true, threshold: 0.1 });
  const isParagraphInView = useInView(paragraphRef, { once: true, threshold: 0.1 });
  const isMentorsInView = useInView(mentorsRef, { once: true, threshold: 0.1 });

  return (
    <div className="full-width bg-gray-100">
      <Nav />
      <div className='flex flex-col w-full items-center justify-center px-4 py-32 mt-20'>
        <div className='flex flex-col justify-center items-center text-center max-w-4xl w-full mb-16'>
          <motion.h2 
            ref={headingRef}
            variants={container}
            initial="hidden"
            animate={isHeadingInView ? "visible" : "hidden"}
            custom={0.2}
            className='text-2xl mb-8 text-transparent bg-clip-text bg-gradient-to-l from-blue-600 to-purple-600'
          >
            About Us
          </motion.h2>
          
          <motion.p 
            ref={paragraphRef}
            variants={container}
            initial="hidden"
            animate={isParagraphInView ? "visible" : "hidden"}
            custom={0.4}
            className='text-md md:text-md text-gray-600 text-center max-w-3xl'
          >
            Our mission is to provide exceptional learning experiences that empower individuals to grow, learn, and succeed.
          </motion.p>
        </div>

        <motion.div
          ref={mentorsRef}
          variants={container}
          initial="hidden"
          animate={isMentorsInView ? "visible" : "hidden"}
          custom={0.6}
          className="w-full"
        >
          <Mentors />
        </motion.div>
      </div>
      <Footer />
    </div>
  );
}

export default About;