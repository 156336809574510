import React, { useRef } from 'react';
import { course_tag, COURSES } from '../Constants/Constants';
import { LinearGradient } from 'react-text-gradients';
import { FaBookOpen, FaUserFriends, FaRegLightbulb, FaClipboardList, FaNewspaper, FaUsers, FaUserTie } from 'react-icons/fa'; 
import { IoManSharp } from "react-icons/io5";
import SpotlightCard from './SpotlightCard';
import { motion, useInView } from "framer-motion";

const container = (delay) => ({
  hidden: { y: 100, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, delay: delay },
  },
});

const whySociologist = [
  {
    id: 1,
    main: "Experienced Faculty",
    icon: <IoManSharp className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Our experienced trainers are the backbone of our institute. They are completely aware of the ins and outs of civil service. They stand beside civil service aspirants until they create their success stories."
  },
  {
    id: 2,
    main: "Comprehensive Study Material",
    icon: <FaBookOpen className="w-12 h-12 text-grey-200 mr-4" />, 
    description: "Get access to well-structured and updated study material designed by experts to cover all aspects of the syllabus."
  },
  {
    id: 3,
    main: "Personalized Mentorship",
    icon: <FaUserFriends className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Receive one-on-one guidance and support from experienced mentors who will help you navigate your preparation journey."
  },
  {
    id: 4,
    main: "In-depth Curriculum",
    icon: <FaRegLightbulb className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Our meticulously designed curriculum covers every aspect of the civil service syllabus in detail. It ensures a deep understanding of core concepts, integrates real-world examples, and follows a structured approach to maximize learning efficiency."
  },
  {
    id: 5,
    main: "Regular Assessments & Feedback",
    icon: <FaClipboardList className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Stay on track with periodic tests and detailed performance analysis to identify strengths and areas for improvement."
  },
  {
    id: 6,
    main: "Current Affairs & Updates",
    icon: <FaNewspaper className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Stay updated with daily current affairs analysis and expert insights on relevant topics."
  },
  {
    id: 7,
    main: "Interactive Learning Environment",
    icon: <FaUsers className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Engage in dynamic discussions, group activities, and interactive sessions to enhance your learning experience."
  },
  {
    id: 8,
    main: "Mock Interviews & Personality Development",
    icon: <FaUserTie className="w-12 h-12 text-grey-200 mr-4" />,
    description: "Prepare for interviews with simulated sessions and expert feedback to boost confidence and communication skills."
  },
];

function CourseMain() {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true, threshold: 0.2 });

  // WhatsApp message handler
  const handleWhatsAppClick = (courseName) => {
    const message = `I'm interested in joining the ${courseName} course, May I know further details!`;
    const whatsappUrl = `https://wa.me/919995160836?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div 
      ref={sectionRef}
      className='flex flex-col items-center justify-center w-full h-full bg-white text-black pt-44 md:pt-64'
    >
      <motion.div 
        variants={container(0.2)}
        initial='hidden'
        animate={isInView ? 'visible' : 'hidden'}
        className='text-center mb-12'
      >
        <h3 className='text-xl pb-2'>
          <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
            Our Courses
          </LinearGradient>
        </h3>
        <h2 className='text-xl pb-2'>
          <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
            Learn from the best MENTORS
          </LinearGradient>
        </h2>
        <h1 className='text-md md:text-md font-light pb-6 text-gray-600'>{course_tag}</h1>
      </motion.div>

      {/* Course Card */}
      <div className='flex flex-col'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 md:px-20'> 
          {COURSES.map((course, index) => (
            <motion.div
              key={index}
              variants={container(0.3 + index * 0.1)}
              initial='hidden'
              animate={isInView ? 'visible' : 'hidden'}
            >
              <SpotlightCard className="custom-spotlight-card" spotlightColor="rgba(192, 109, 248, 0.2)">
                <div className='flex flex-col items-center justify-center rounded-lg shadow-lg overflow-hidden max-w-xs mx-auto'>
                  <img 
                    src={course.image} 
                    alt={course.name} 
                    className='w-40 h-40 mb-4 rounded-lg'
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `/api/placeholder/160/160`;
                    }}
                  />
                  <div className='p-4'>
                    <h2 className='text-lg font-bold mb-2'>
                      <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
                        {course.name}
                      </LinearGradient>
                    </h2>
                    <p className='text-sm font-light mb-4 text-gray-300'>{course.description}</p>
                    <button 
                      onClick={() => handleWhatsAppClick(course.name)}
                      className='w-full border-2 rounded-lg px-4 py-2 border-blue-500 text-blue-500 transition duration-300 hover:bg-gradient-to-r from-[#2A52BE] to-[#8A2BE2] hover:text-white'
                    >
                      Join Course
                    </button>
                  </div>
                </div>
              </SpotlightCard>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Study Materials Section */}
      <div className='pt-12'>
        <p>Study Materials</p>
      </div>

      {/* Why us? */}
      <motion.div 
        variants={container(0.4)}
        initial='hidden'
        animate={isInView ? 'visible' : 'hidden'}
        className='flex flex-col mt-12 items-center py-8 px-4 md:px-8 lg:px-32'
      >
        <h6 className='text-xl'>
          <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
            -Why Sociologist?
          </LinearGradient>
        </h6>
        <p className='text-2xl text-gray-600 mt-4'>Why Choose Sociologist For IAS Coaching?</p>
      </motion.div>

      {/* Why Sociologist Points */}
      <div className='flex flex-col lg:flex-row lg:flex-wrap lg:justify-evenly gap-8 px-4 md:px-8 lg:px-32 shadow-md'>
        {whySociologist.map((point, index) => (
          <motion.div
            key={point.id}
            variants={container(0.5 + index * 0.1)}
            initial='hidden'
            animate={isInView ? 'visible' : 'hidden'}
            className='p-4 border my-4 w-full lg:w-1/3 bg-gradient-to-r from-[#2A52BE] to-[#8A2BE2] text-white rounded-lg shadow-md transition-transform hover:scale-105'>
            <div className='flex items-start'> 
              {point.icon} 
              <div>
                <h2 className='text-md font-semibold mb-1'>{point.main}</h2>
                <p className='text-sm text-gray-200'>{point.description}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default CourseMain;