import React, { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import logowhite from "../Pics/SOCIOLOGIST White.png"
import logo from '../Pics/Horizontal logo.png'

const Nav = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const NavLinks = () => (
    <>
      <NavLink
        to="/"
        className={({ isActive }) => 
          isActive ? 'text-blue-500 font-bold' : 'hover:text-blue-600'
        }
      >
        Home
      </NavLink>
      <NavLink
        to="/about"
        className={({ isActive }) => 
          isActive ? 'text-blue-500 font-bold' : 'hover:text-blue-600'
        }
      >
        About
      </NavLink>
      <NavLink
        to="/courses"
        className={({ isActive }) => 
          isActive ? 'text-blue-500 font-bold' : 'hover:text-blue-600'
        }
      >
        Courses
      </NavLink>
      <NavLink
        to="/blog"
        className={({ isActive }) => 
          isActive ? 'text-blue-500 font-bold' : 'hover:text-blue-600'
        }
      >
        Blog
      </NavLink>
      <NavLink
        to="/contact"
        className={({ isActive }) => 
          isActive ? 'text-blue-500 font-bold' : 'hover:text-blue-600'
        }
      >
        Contact
      </NavLink>
    </>
  );

  return (
    <nav className="fixed top-0 left-0 w-full z-50">
      {/* Top bar */}
      <div className="bg-gradient-to-r from-[#2A52BE] to-[#8A2BE2] text-white py-2 flex items-center justify-between px-4">
      <p className="px-4 md:px-12 text-sm font-extralight">
          The Sociologist,
          Convent Rd,<br /> Vanchiyoor,
          Thiruvananthapuram, Kerala 695035
        </p>
        <img
          onClick={() => navigate('/')}
          src={logowhite}
          alt="Logo"
          className="h-12 w-auto cursor-pointer px-4 md:px-12"
        />
      </div>

      {/* Desktop Navigation */}
      <div className="hidden md:flex items-center py-6 px-4 bg-white ">
        <img src={logo} className='h-16 w-32'/>
           <div className="flex-1 flex justify-center items-center text-gray-800 text-xl font-normal gap-10">
            <NavLinks />
           </div>
      </div>
      {/* Mobile Menu Button */}
<div className="md:hidden flex justify-between bg-white p-4">
  <div className="flex-1 flex justify-center">
    <img src={logo} className='h-16 w-32'/>
  </div>
  <button
    onClick={toggleMenu}
    className="text-gray-700 hover:text-blue-600 focus:outline-none">
    {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
  </button>
</div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-white shadow-lg">
          <div className="flex flex-col space-y-4 px-4 py-6 text-lg">
            <NavLinks />
          </div>
        </div>
      )}
    </nav>
  );
};

export default Nav;