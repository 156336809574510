import React from 'react';
import logo from '../Pics/SOCIOLOGIST White.png';
import { FaInstagram, FaFacebook, FaYoutube, FaTwitter } from "react-icons/fa"; 
import { IoIosCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";

function Footer() {
  // WhatsApp message handler
  const whatsappMessage = "I'm interested in learning more about your courses!,May I know further details?";
  const whatsappUrl = `https://wa.me/919995160836?text=${encodeURIComponent(whatsappMessage)}`;

  return (
    <div className='flex flex-col items-center justify-center w-full bg-gradient-to-r from-[#2A52BE] to-[#8A2BE2] text-white border-t border-black-900 py-10'>
      <div className='mb-6'>
        <img 
          src={logo} // Replace with your logo path
          alt="Logo" 
          className="h-16 w-auto cursor-pointer"
        />
      </div>

      {/* Social Media Links */}
      <div className='flex space-x-8'>
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FaFacebook className='text-2xl cursor-pointer hover:text-blue-600 transition-colors' />
        </a>
        <a href="https://www.instagram.com/thesociologistupsc/" target="_blank" rel="noopener noreferrer">
          <FaInstagram className='text-2xl cursor-pointer hover:text-pink-600 transition-colors' />
        </a>
        <a href="https://www.youtube.com/@TheSociologistUPSC" target="_blank" rel="noopener noreferrer">
          <FaYoutube className='text-2xl cursor-pointer hover:text-red-600 transition-colors' />
        </a>
        <a href="https://x.com/sociologistUPSC" target="_blank" rel="noopener noreferrer">
          <FaTwitter className='text-2xl cursor-pointer hover:text-blue-400 transition-colors' />
        </a>
        <a href="tel:+919995160836" target="_blank" rel="noopener noreferrer">
          <IoIosCall className='text-2xl cursor-pointer hover:text-blue-400 transition-colors' />
        </a>
        {/* Updated WhatsApp Link */}
        <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
          <FaWhatsapp className='text-2xl cursor-pointer hover:text-green-400 transition-colors' />
        </a>
      </div>

      {/* Copyright Section */}
      <div className='mt-6 text-sm'>
        © {new Date().getFullYear()} Pvt. Ltd. All rights reserved.
      </div>
    </div>
  );
}

export default Footer;