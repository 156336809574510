import React from 'react';
import { useLocation } from 'react-router-dom';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';

function BlogDetail() {
  const location = useLocation();
  const { title, content } = location.state || {}; // Safely destructure from state

  if (!location.state) {
    return (
      <div className='flex flex-col min-h-screen'>
        <Nav />
        <div className='flex items-center justify-center flex-grow bg-gray-100'>
          <div className='text-center p-4'>
            <h2 className='text-2xl font-semibold text-gray-700'>Blog Content Not Found</h2>
            <p className='text-gray-500 mt-2'>Please return to the blog and select a valid article.</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className='flex flex-col min-h-screen'>
      <Nav />
      <div className='flex flex-col items-center justify-center py-36 bg-gray-100 flex-grow'> {/* Adjusted padding and added flex-grow */}
        <div className='max-w-5xl w-full p-8 bg-transparent  rounded-xl'>
          <h1 className='text-4xl font-bold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-l from-blue-600 to-purple-600'>
            {title}
          </h1>
          <p className='text-gray-600 leading-relaxed text-start'>{content}</p>
          <div className='flex justify-center mt-8'>
            <button
              onClick={() => window.history.back()}
              className='border border-blue-500 text-blue-500 rounded-lg py-2 px-4 bg-transparent transition duration-700 hover:bg-gradient-to-r from-[#2A52BE] to-[#8A2BE2] hover:text-white'
            >
              Back to Blog
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogDetail;
