import React, { useRef } from 'react';
import { hmcourse1, hmcourse2 } from '../Constants/Constants';
import { COURSES } from '../Constants/Constants';
import { useNavigate } from 'react-router-dom';
import { LinearGradient } from 'react-text-gradients';
import { motion, useInView } from "framer-motion";

// Animation variants
const container = (delay) => ({
  hidden: { y: 100, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, delay: delay },
  },
});

function Course() {
  const navigate = useNavigate();
  
  // Create ref for detecting when section is in view
  const courseRef = useRef(null);
  const isInView = useInView(courseRef, { once: true, threshold: 0.2 });

  return (
    <div 
      ref={courseRef}
      className='pt-20 h-full w-full flex flex-col items-center justify-center bg-transparent text-black py-10 overflow-y-hidden'
    >
      <div className='flex flex-col text-center items-center mx-8 pb-10'> 
        <motion.h6 
          variants={container(0.2)}
          initial='hidden'
          animate={isInView ? 'visible' : 'hidden'}
          className='text-xl mb-4'
        >
          <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
            Our Courses            
          </LinearGradient>
        </motion.h6> 
        
        <motion.h1 
          variants={container(0.3)}
          initial='hidden'
          animate={isInView ? 'visible' : 'hidden'}
          className='text-2xl mb-4 font-bold'
        >
          <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
            {hmcourse1}    
          </LinearGradient>
        </motion.h1>
        
        <motion.h6 
          variants={container(0.4)}
          initial='hidden'
          animate={isInView ? 'visible' : 'hidden'}
          className='text-md mb-4 text-gray-600'
        >
          {hmcourse2}
        </motion.h6>
        
        <motion.div 
          variants={container(0.5)}
          initial='hidden'
          animate={isInView ? 'visible' : 'hidden'}
        >
          <button 
            className='border-2 bg-gray-700 text-white rounded-xl py-4 px-6 mx-4 duration-700 transition-transform transform hover:bg-gradient-to-r from-[#2A52BE] to-[#8A2BE2]' 
            onClick={() => navigate('/courses')}
          >
            Explore Course
          </button>
        </motion.div>
      </div>

      <div className='flex flex-col'>
        <div className='flex flex-col lg:flex-row lg:flex-wrap lg:justify-evenly gap-14'>
          {COURSES.map((course, index) => (
            <motion.div 
              key={index}
              variants={container(0.6 + index * 0.1)} // Staggered delay for each course card
              initial='hidden'
              animate={isInView ? 'visible' : 'hidden'}
              className='p-4 my-4 w-full lg:w-1/3 rounded-lg shadow-lg'
            >
              <img 
                src={course.image} 
                alt={course.name} 
                className='w-40 h-40 object-cover'
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `/api/placeholder/160/160`;
                }}
              />
              
              <h2 className='text-xl font-bold mb-2'>
                <LinearGradient gradient={['to left', '#2A52BE ,#8A2BE2']}>
                  {course.name} 
                </LinearGradient>
              </h2>
              
              <p className='text-sm font-light mb-4 text-gray-600'>{course.description}</p>
              
              {/* Updated Learn More Button */}
              <button 
                className='border border-blue-500 text-blue-500 rounded-lg py-2 px-4 bg-transparent transition duration-700 hover:bg-gradient-to-r from-[#2A52BE] to-[#8A2BE2] hover:text-white'
                onClick={() => navigate('/courses')} // Corrected syntax with quotes
              >
                Learn More
              </button>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Course;